import { Api } from "./api";

export class InvoiceService {
  getHeaderInfo({ referenceNumber, token }) {
    return Api.get(
      `/iam-entry/invoices/${referenceNumber}/header-info?token=${encodeURIComponent(
        token
      )}`
    ).then((res) => res.data);
  }
}
