import { useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { BASE_WEB_SOCKET_URL } from "../config";

export const SignProgress = (props) => {
  const [header, setHeader] = useState("");
  const [listening, setListening] = useState(false);
  const [keepAlive, setKeepAlive] = useState(0);

  useEffect(() => {
    const origin = window.location.origin
      .replace("http", "ws")
      .replace("https", "wws");
    const webSocket = new WebSocket(
      `${origin}/${BASE_WEB_SOCKET_URL}?clientId=${props.clientId}`
    );

    webSocket.addEventListener("open", () => {
      webSocket.send("subscribe:BuyerSignedResult");
    });

    webSocket.onclose = (e) => {
      if (e.reason !== "unmount") {
        setKeepAlive(Date.now);
      }
    };
    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      if (data["topic"] === "BuyerSignedResult") {
        props.onSignComplete();
        if (listening) {
          setHeader("Xác nhận ký kết thúc, vui lòng xem lại kết quả!");
          setListening(false);
        }
      }
    };
    return () => {
      webSocket.close(3010, "unmount");
    };
  }, [keepAlive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.displaySignProgress > 0) {
      setHeader("Chờ xác nhận chữ ký số...");
      setListening(true);
    }
  }, [props.displaySignProgress]);

  let footer = null;
  if (!listening) {
    footer = (
      <div>
        <Button
          label="Đóng"
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => props.setDisplaySignProgress(0)}
        />
      </div>
    );
  }

  return (
    <Dialog
      header={header}
      footer={footer}
      visible={!!props.displaySignProgress}
      style={{ width: "50rem" }}
      onHide={() => props.setDisplaySignProgress(0)}
    >
      {listening && (
        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
      )}
      {!listening && (
        <div style={{ width: "20rem", margin: "0 auto", textAlign: "center" }}>
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "6rem", color: "green" }}
          />
        </div>
      )}
    </Dialog>
  );
};
