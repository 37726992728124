import { Link, Route } from "react-router-dom";

import { TraCuu } from "./tra-cuu";

export const App = () => {
  return (
    <div className="App">
      <div className="layout-topbar">
        <Link to="/">
          <img
            width="102"
            height="32"
            src={"https://cdn.bizzi.services/bizzi-logo.svg"}
            alt="logo"
          />
        </Link>
      </div>

      <div className="layout-content">
        <Route path="/" exact component={TraCuu} />
      </div>

      <div className="layout-footer">
        <span className="title-medium">CÔNG TY TNHH BIZZI VIETNAM</span>
        <div className="box-center">
          <i className="pi pi-map-marker mr-2" />
          <span className="text-secondary">
            <b>Địa chỉ</b>: Số 2-4-6 Đường Số 5, Phường An Lợi Đông, Thành phố
            Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam.
          </span>
        </div>
        <div className="box-center">
          <i className="pi pi-phone mr-2" />
          <span className="text-secondary">
            <b>Hotline:</b> 028 6275 0321 / 028 3622 2368
          </span>
        </div>
      </div>
    </div>
  );
};
