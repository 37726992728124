import { useState, useRef, useCallback } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import Turnstile, { useTurnstile } from "react-turnstile";

import { InvoiceService } from "../../service/InvoiceService";

import { HoaDon } from "./HoaDon";

import "./styles.scss";

const invoiceService = new InvoiceService();

const ITEMS_BENEFIT = [
  {
    img: "/assets/images/nghiep-vu.png",
    title: "ĐÁP ỨNG NGHIỆP VỤ",
    content:
      "Đảm bảo đáp ứng toàn bộ nghiệp vụ theo quy định của Tổng cục thuế.",
  },
  {
    img: "/assets/images/hieu-qua.png",
    title: "TIẾT KIỆM - HIỆU QUẢ",
    content:
      "Tiết kiệm 90% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn.",
  },
  {
    img: "/assets/images/an-toan.png",
    title: "AN TOÀN - BẢO MẬT",
    content:
      "Sử dụng công nghệ bảo mật nhiều lớp, an toàn tuyệt đối trong việc quản lý và lưu trữ.",
  },
  {
    img: "/assets/images/tu-van.png",
    title: "TƯ VẤN - HỖ TRỢ",
    content:
      "Triển khai nhanh, hỗ trợ thông báo phát hành hóa đơn, tư vấn nghiệp vụ trong việc sử dụng hóa đơn.",
  },
];

export const TraCuu = () => {
  const turnstile = useTurnstile();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [headerInfo, setHeaderInfo] = useState({});
  const [display, setDisplay] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(null);

  const lookup = useCallback(() => {
    setLoading(true);
    invoiceService
      .getHeaderInfo({
        referenceNumber: value,
        token: captchaToken,
      })
      .then((data) => {
        if (data["invoiceTotal"] && data["currencyUomId"]) {
          data["invoiceTotalLocaleString"] = data[
            "invoiceTotal"
          ].toLocaleString("vi-VN", {
            style: "currency",
            currency: data["currencyUomId"],
          });
        }
        setHeaderInfo(data);
        setReferenceNumber(value);
        setDisplay(Date.now);
      })
      .catch(({ response }) => {
        toast.current.show({
          severity: "error",
          summary: "Thông báo",
          detail: response?.data?.errors ?? "Có lỗi xảy ra...",
          life: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
        turnstile.reset();
      });
  }, [value, captchaToken, turnstile]);

  const onEnterLook = (e) => {
    if (e.key === "Enter" && value) {
      lookup();
    }
  };

  return (
    <>
      <HoaDon
        referenceNumber={referenceNumber}
        display={display}
        setDisplay={setDisplay}
        headerInfo={headerInfo}
      />
      <div className="invoicePage">
        <Toast ref={toast} />
        <div className="invoiceSearch">
          <div className="boxSearch">
            <span className="title">TRA CỨU HÓA ĐƠN ĐIỆN TỬ</span>
            <div className="form">
              <span className="p-input-icon-left search">
                <i className="pi pi-search" />
                <InputText
                  onKeyDown={(e) => onEnterLook(e)}
                  value={value}
                  className="search"
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Nhập mã tra cứu"
                />
              </span>
              <Turnstile
                sitekey="0x4AAAAAAAMD2FiVu57yrzOk"
                theme="light"
                onVerify={setCaptchaToken}
              />

              <Button
                className="p-button-rounded p-button-success"
                label="Tra cứu"
                disabled={!value || !captchaToken}
                icon="pi pi-search"
                loading={loading}
                onClick={lookup}
              />
            </div>
          </div>
        </div>
        <div className="benefit">
          <span className="title">Lợi ích hóa đơn điện tử</span>
          <div className="boxCards">
            {ITEMS_BENEFIT.map((item, index) => (
              <div className="card" key={index}>
                <img src={item.img} alt={item.title} />
                <span className="title">{item.title}</span>
                <span className="content">{item.content}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
