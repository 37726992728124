import { Fragment, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { SignProgress } from "../components/SignProgress";
import { BASE_URL } from "../config";

export const HoaDon = (props) => {
  const [clientId, setClientId] = useState("");
  const [displayConvertDialog, setDisplayConvertDialog] = useState(false);
  const [name, setName] = useState("");
  const [displaySignProgress, setDisplaySignProgress] = useState(0);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    setClientId("" + Date.now() + Math.random() * 1000);
  }, []);

  const downloadPdf = () => {
    window.open(`${BASE_URL}/iam-entry/invoices/${props.referenceNumber}/pdf`);
  };

  const downloadXml = () => {
    window.open(`${BASE_URL}/iam-entry/invoices/${props.referenceNumber}/xml`);
  };

  const sign = () => {
    window.location.href = `einvoicesign:anonymous,${clientId},invoiceSignedByBuyer,${props.referenceNumber}`;
    setDisplaySignProgress(Date.now);
  };

  const onSignComplete = () => {
    setVersion(Date.now);
  };

  const showConvertDialog = () => {
    setDisplayConvertDialog(true);
  };

  const downloadConvertedInvoice = () => {
    if (name && name.trim()) {
      window.open(
        `${BASE_URL}/iam-entry/invoices/${props.referenceNumber}/converted?convertedName=${name}`
      );
      setDisplayConvertDialog(false);
    }
  };

  return (
    <Fragment>
      {clientId && (
        <SignProgress
          clientId={clientId}
          displaySignProgress={displaySignProgress}
          setDisplaySignProgress={setDisplaySignProgress}
          onSignComplete={onSignComplete}
        />
      )}
      <Dialog
        header="Chuyển thành hóa đơn giấy"
        visible={displayConvertDialog}
        style={{ width: "400px" }}
        onHide={() => setDisplayConvertDialog(false)}
      >
        <form onSubmit={downloadConvertedInvoice}>
          <div className="grid mt-2">
            <div className="col-12">
              <div className="field p-fluid">
                <label htmlFor="name">Họ và tên người chuyển đổi</label>
                <InputText
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-12 text-right">
              <Button
                label="Tải xuống"
                icon="pi pi-check"
                type="submit"
                className="p-button-sm mr-2"
              />
              <Button
                label="Đóng"
                icon="pi pi-times"
                type="button"
                onClick={() => setDisplayConvertDialog(false)}
                className="p-button-sm p-button-secondary"
              />
            </div>
          </div>
        </form>
      </Dialog>
      <Dialog
        header="Thông tin hóa đơn"
        visible={!!props.display}
        style={{ width: "100vw", maxWidth: "1200px", height: "90%" }}
        position="top"
        onHide={() => props.setDisplay(0)}
      >
        <div className="grid" style={{ height: "100%" }}>
          <div className="col-fixed w-900">
            {props.referenceNumber && (
              <iframe
                src={`${BASE_URL}/iam-entry/invoices/${props.referenceNumber}/pdf?v=${version}`}
                width="100%"
                height="100%"
                title={props.referenceNumber}
              />
            )}
          </div>
          <div className="col">
            <div className="surface-section">
              <div className="font-medium text-lg mb-3">Thông tin hóa đơn</div>
              <div className="grid border-bottom-1 surface-border text-xs">
                <div className="col-fixed" style={{ width: "138px" }}>
                  Số hóa đơn:
                </div>
                <div className="col text-right">
                  {props.headerInfo["invoiceNo"]}
                </div>
              </div>
              <div className="grid border-bottom-1 surface-border text-xs mt-1">
                <div className="col-fixed" style={{ width: "138px" }}>
                  Tổng tiền thanh toán:
                </div>
                <div className="col text-right">
                  {props.headerInfo["invoiceTotalLocaleString"]}
                </div>
              </div>
              <div className="grid border-bottom-1 surface-border text-xs mt-1">
                <div className="col-fixed" style={{ width: "138px" }}>
                  Hạn thanh toán:
                </div>
                <div className="col text-right">Không có hạn</div>
              </div>
            </div>
            <div className="grid p-fluid mt-5">
              <div className="col-12 pb-0">
                <Button
                  label="Tải hóa đơn PDF"
                  icon="pi pi-file-pdf"
                  className="p-button-outlined p-button-sm"
                  onClick={downloadPdf}
                />
              </div>
              <div className="col-12 pb-0">
                <Button
                  label="Tải hóa đơn XML"
                  icon="pi pi-download"
                  className="p-button-outlined p-button-sm p-button-secondary"
                  onClick={downloadXml}
                />
              </div>
              <div className="col-12 pb-0">
                <Button
                  label="Chuyển thành hóa đơn giấy"
                  icon="pi pi-copy"
                  className="p-button-outlined p-button-sm p-button-success"
                  onClick={showConvertDialog}
                />
              </div>
              <div className="col-12">
                <Button
                  label="Ký hóa đơn"
                  icon="pi pi-pencil"
                  className="p-button-outlined p-button-sm p-button-warning"
                  onClick={sign}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
